.flowchart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flowchart-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.flowchart-item {
  text-align: center;
  margin: 0 10px;
  white-space: nowrap;
  width: 150px;
  cursor: pointer; /* Change the cursor to pointer on hover */
  transition: transform 0.2s, color 0.2s, filter 0.2s; /* Add transition for hover effects */
}

.flowchart-item:hover {
  transform: scale(1.08); /* Scale up the item on hover */
  filter: brightness(1.2); /* Increase brightness on hover */
}

.arrow {
  margin: 0 10px;
  color: #A0A0A0; /* Neutral gray for arrows */
}

.flowchart-item p {
  margin-top: 10px;
  font-size: 14px;
  white-space: normal;
  color: #333;
}
