body {
  font-family: 'Arial', sans-serif; 
  line-height: 1.6; 
}

p {
  font-size: 16px; 
  line-height: 1.5; 
  margin-bottom: 1em; 
  color: #1e1e1e;
}

h1, h2, h3,h5 {
  font-family: 'Arial', sans-serif; 
  color: #111; 
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-weight: bold; 
}

h1 {
  font-size: 32px; 
}

h2 {
  font-size: 28px; 
}

h3 {
  font-size: 24px;
}
h5 {
  font-size: 20px;
}

ul,ol{
  font-size: 16px; 
  margin-bottom: 1em;
  color: rgb(47, 46, 46);
}

/* Smaller screen adjustments */
@media (max-width: 768px) {
  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 20px;
  }

  p {
    font-size: 14px;
  }
}
