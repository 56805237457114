.health-benefit-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    color: #333;
}

.health-benefit-header {
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold; 
}

.health-benefit-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    gap: 20px;
}

.health-benefit-card {
    background: #f9f9f9;
    border: 1px solid #ddd;
    padding: 20px;
    width: calc(25% - 40px); /* Adjust width to fit four cards per row */
    text-align: center;
    border-radius: 10px;
    box-sizing: border-box; /* Ensures padding and border are included in width */
}
.benefit-card {
    margin-bottom: 10px;
    width: 130px;
    height:90px;
}

.benefit-icon {
    margin-bottom: 10px;
}

.benefit-title {
    font-size: 18px;
    margin-bottom: 10px;
}

.benefit-description {
    font-size: 14px;
    color: #666;
}
@media (max-width: 1200px) {
    .health-benefit-card {
        width: calc(33.33% - 40px); /* 3 cards per row */
    }
}

@media (max-width: 900px) {
    .health-benefit-card {
        width: calc(50% - 40px); /* 2 cards per row */
    }
}

@media (max-width: 600px) {
    .health-benefit-card {
        width: calc(100% - 40px); /* 1 card per row */
    }
}

  