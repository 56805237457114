.checklist-container {
  margin-top: 20px;
  display: flex;
  justify-content: center; /* Center the boxes horizontally */
  flex-wrap: wrap; /* Adjust layout for smaller screens */
  width: 100%;
}
.checklist-container p{
  font-weight: bold;
}

.checklist-box {
  border: 1px solid #ccc;
  padding: 20px; /* Increase padding for better spacing inside */
  background-color: #fff;
  margin: 20px 20px; 
  width: 100%; /* Make boxes smaller in width */
}

.checklist-item {
  display: flex;
  margin-bottom: 10px;
}

.form-check-input {
  margin-right: 10px;
}

.form-check-label {
  margin-left: 5px;
  font-size: 1.1em;
}

.form-control {
  width: 80%; /* Make the input field take more width */
  margin: 0 auto; /* Center the input */
}

.download-button {
  padding: 10px;
  font-size: 1em;
  margin-top: 15px;
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(135deg, #f7959d, #fbd3e1); 
  border: none; /* Ensure no border */
  color: white;
  width: 100%;
}

.download-button:hover {
  background: linear-gradient(to right, #c5528b, #ff66cc);
}

.input-group .btn {
  background: linear-gradient(135deg, #f7959d, #fbd3e1);
  color: white;
  border: none;
}

.input-group .btn:hover {
  background: linear-gradient(to right, #c5528b, #ff66cc);
}
