.term-card {
    width: 500px;
    height: 400px;
    border: 1px solid #ccc;
    perspective: 1000px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.6s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .term-card.flipped {
    transform: rotateY(180deg);
  }
  
  .term-card-front {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .term-card-front {
    font-size: 40px; /* Make the term text larger */
    font-weight: bold; /* Make the term text bold */
  }
  
  .term-card-back {
    font-size: 10px; /* Adjust the font size for the description */
    padding: 20px; /* Add some padding for better readability */
    transform: rotateY(180deg);
  }
  
  .term-card-back p {
    margin: 10px; /* Remove any default margin */
    text-align: center; /* Center-align the description text */
    color:black
  }
  .term-card-image {
    margin-bottom: 5px; /* Add spacing below the image */
  }
  
  