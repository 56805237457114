.medical-hub .centered-header {
    text-align: center;
    align-items: center;
    justify-content: center;
    margin: 20px;
  }
  
 .introduction {
    text-align: center;
    margin: 0 auto 20px;
    padding: auto;
    max-width: 800px;
    line-height: 1.5;
    
  }
  
  .accordion-section {
    border-bottom: 1px solid #ccc;
    width: 80%;
    margin: 20px;
    margin-left: 200px;
}

.accordions {
    align-items: center;
    justify-content: center;
}

/* Gradient Accordion Titles */
.accordion-title {
    background: linear-gradient(135deg, #FBD3E1, #F7A1C4); /* Pink gradient */
    color: #4A2040;
    cursor: pointer;
    padding: 18px;
    font-size: 20px;
    width: 100%;
    text-align: left;
    border-radius: 5px;
    outline: none;
    transition: background-color 0.6s ease, color 0.4s ease;
    font-weight: bold;
}

/* Adding more gradient colors for different sections */
.accordion-title:nth-child(1) {
    background: linear-gradient(135deg, #FDEBDA, #fcc8c6); /* Peach to light pink */
}

.accordion-title:nth-child(2) {
    background: linear-gradient(135deg, #D4F1F4, #B2ECE1); /* Light blue to teal */
}

.accordion-title:nth-child(3) {
    background: linear-gradient(135deg, #FFF5DA, #FFE4B2); /* Light yellow to soft orange */
}

.accordion-title:nth-child(4) {
    background: linear-gradient(135deg, #D6F5E9, #AFE1D0); /* Soft green to mint */
}

.accordion-title.open {
    background: linear-gradient(135deg, #f7e6ec, #fcd0e4); /* Pink shade */
}

.accordion-title:hover {
    background: linear-gradient(135deg, #e7f8f6, #fbe6ed); /* Hover: Pink & purple */
    color: white;
}

.toggle-icon {
    float: right;
}

.accordion-content {
    padding: 0 18px;
    background-color: white;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

@media (max-width: 768px) {
    .accordion-title {
        font-size: 16px;
        padding: 8px 16px;
    }
}

@media (max-width: 480px) {
    .accordion-section {
        flex-direction: column;
    }

    .accordion-title {
        font-size: 14px;
        padding: 6px 12px;
    }
}
  .arrow-icon {
    display: flex;  
    justify-content: center; 
    margin: 10px 0; 
  }
  
  .medicare-container {
    padding: 20px;
    line-height: 1.6;
    font-family: Arial, sans-serif;
  }
  
  .medicare-title {
    margin-bottom: 10px;
  }
  
  .medicare-list {
    padding-left: 20px;
    margin-top: 10px;
    list-style-type: disc;
    font-size: 16px; 
    color: rgb(47, 46, 46);
  }
  .bulk-billing-container {
    padding: 20px;
    line-height: 1.6;
    font-family: Arial, sans-serif;
  }
  
  .bulk-billing-title {
    margin-bottom: 10px;
  }
  
  .bulk-billing-list {
    padding-left: 20px;
    margin-top: 10px;
    list-style-type: disc;
    font-size: 16px; 
    color: rgb(47, 46, 46);
  }

  .emergency-container {
    align-items: flex-start; 
    justify-content: center; 
    text-align:center ;
    margin: 20px; 
  }
  .bold-text {
    font-weight: bold;
    text-align:center ;
  }

/* Center the search bar container */
.search-container{
  display: flex;
  justify-content: center; /* Center the content horizontally */
  margin: 20px 0;
  width: 100%; /* Ensure the container takes full width */
}

.search-wrapper {
  position: relative;
  width: 100%; /* Ensure the wrapper takes up the full width of the container */
  max-width: 600px; /* Limit the maximum width */
}

.search-input-call {
  width: 100%; /* Ensure the input takes up the full width of the wrapper */
  padding: 0.75rem 2.5rem 0.75rem 1rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.clear-search-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.search-button {
  padding: 0.75rem 1.5rem;
  margin-left: 10px; /* Space between input and button */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}



  

  
  
  
  
  
  
  
  

  
  
  
  
  
  
  
  
  