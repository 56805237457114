.navbar {
  display: flex;
  background-color: #ffffff;
  padding: 0 1rem;
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  height: 80px;
}

.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-links li {
  padding: 0 15px;
  font-size: 20px;
}

.nav-links a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  position: relative;
}

/* Adding underlines for active and hover states */
.nav-links a:hover::after,
.nav-links a.active::after {
  content: '';
  display: block;
  height: 2px;
  background-color: #000;
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
}

.nav-links a.active::after {
  background-color: #000;
}

/* Mobile Responsive Styles */
.hamburger-icon {
  display: none;
  font-size: 30px;
  cursor: pointer;
  color: #333;
}

/* Language Selector */
.language-selector-wrapper {
  margin-left: 15px;
  display: flex;
  align-items: center;
}

/* Nav Menu */
.nav-menu {
  display: flex;
  align-items: center;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .nav-menu {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 100px;
      left: 0;
      width: 100%;
      background-color: #fff;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      transition: max-height 0.3s ease;
  }

  .nav-menu.open {
      display: flex;
  }

  .nav-links {
      flex-direction: column;
  }

  .hamburger-icon {
      display: block;
  }

  .nav-links li {
      padding: 15px;
      text-align: center;
  }

  .nav-links a {
      font-size: 18px;
  }

  .language-selector-wrapper {
      justify-content: center;
      margin-top: 10px;
  }
}

@media (min-width: 769px) {
  .hamburger-icon {
      display: none;
  }
}
