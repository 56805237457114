.contact-card {
  border: 1px solid #000;
  padding: 20px;
  margin: 10px;
  flex: 1; /* Allow cards to grow/shrink based on available space */
  min-width: 250px;
  max-width: 300px;
  text-align: left;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.contact-card h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;  
}

.contact-card p {
  font-size: 14px;
}

.icon-text {
  display: flex;
  align-items: center;  /* Align the icon and text */
  justify-content: flex-start;
  margin: 10px 0;
}

.icon {
  width: 24px;  /* Set a fixed width for the icon */
  height: 24px;  /* Set a fixed height for the icon */
  margin-right: 10px;  /* Add some space between the icon and the text */
  flex-shrink: 0;  /* Prevent the icon from shrinking */
}

.icon-text span {
  font-size: 14px;
  word-wrap: break-word; /* Ensure long text wraps */
}

/* Flexbox container for the cards */
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Minimize spacing between cards */
  gap: 10px; /* Reduce the space between cards */
  padding: 20px; /* Add some padding around the container */
}

@media (max-width: 768px) {
  .card-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .card-container {
    grid-template-columns: 1fr;
  }
}

.card-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
}


  
  