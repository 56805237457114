/* PasswordPage.css */

.password-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  .password-container {
    background: white;
    padding: 100px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    max-width: 800px;
    height: 600px;
    width: 100%;
  }
  
  .password-title {
    font-size: 40px;
    margin-bottom: 50px;
    background: linear-gradient(135deg, #f7959d, #fbd3e1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
  }
  
  .password-prompt {
    font-size: 16px;
    margin-bottom: 50px;
  }
  
  .password-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .password-input-container {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    width: 100%;
  }
  
  .lock-icon {
    font-size: 24px;
    margin-right: 10px;
  }
  
  .password-input {
    border: none;
    outline: none;
    width: 100%;
    font-size: 16px;
  }
  
  .password-submit {
    background: linear-gradient(135deg, #f7959d, #fbd3e1);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .password-submit:hover {
    background: linear-gradient(135deg, #fbd3e1, #f7959d);
  }
  
  .password-error {
    color: red;
    margin-top: 10px;
  }
  
  