.term-carousel {
    text-align: center;
  }
  
  .carousel-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .arrow {
    font-size: 3rem;
    cursor: pointer;
    margin: 0 20px;
    transition: transform 0.3s;
  }
  .arrow:hover {
    transform: scale(1.2);
  }
  .term-card img, .term-card svg {
    width: 130px;
    height: 120px;
  }
  
  .term-card {
    margin: 0 20px;
    max-width: 600px;
  }

  .term-card p {
    margin-top: 10px;
    font-size: 1.2rem; 
  }

  
  .term-carousel p {
    margin: 20px;
    font-weight: bold; 

  }
  @media (max-width: 768px) {
  
    .term-card img, .term-card svg {
      width: 80px;
      height: 80px;
    }

  }
  
  @media (max-width: 480px) {
    .arrow {
      font-size: 1.2rem;
    }
  
    .term-card img, .term-card svg {
      width: 60px;
      height: 60px;
    }
  
    .term-card {
      max-width: 200px;
    }
    .term-card p {
      font-size: 0.9rem; /* Smaller font size for small screens */
    }
  }
  