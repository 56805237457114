.home-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: #f0f0f0; 
    width: 100%;
    top: 0;
}

.left-content img {
    width: 100%;
    height: 500px;
    margin: 50px;
}

.right-content {
    padding: 50px;
    width: 30%;
    margin: 50px;
    align-items: center;
}

h1 {
    font-size: 50px;
    color: #333;
    align-items: center;
    margin: 10px;
}
h2 {
    font-size: 30px;
    align-items: center;
    margin: 20px;
}
p {
    color: #666;
    font-size: 20px;
    align-items: center;
}

.buttons {
    margin-top: 35px;
    align-items: center;
}

.btn {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 20px;
    font-weight: bold;
    cursor: pointer;
    background: linear-gradient(135deg, #f7959d, #fbd3e1); 
    color: white;
}
.features-section {
    text-align: center;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.features {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    width: 100%;

}

.feature {
    text-align: center;
    padding: 20px;
    flex: 1 1 200px; 
    min-width: 200px; 
    max-width: 300px; 
}

.feature p {
    font-size: 14px;
    word-wrap: break-word; 
    margin: 10px 0; 
    text-align: center;
}

.feature h3 {
    margin-top: 10px;
}
.generation-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.generation-card {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px;
    width: 90%;
}

.generation-card img {
    width: 40%; 
    height: 450px;
    margin: 50px;
    align-items: center;
    justify-content: center;

}

.card-text {
    flex: 1;
    padding: 20px;
    margin: 50px;
    align-items: center;
    justify-content: center;
    width: 40%; 
    height: 450px;
}

.card-text h3 {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 30px;
    margin: 30px;
}

.card-text p {
    margin-bottom: 40px;
    align-items: center;
    font-size: 18px;
    margin: 20px;
}

