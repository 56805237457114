/* Basic footer styling */
.footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    padding: 10px; 
    background-color: #f3f3f3; 
    border-top: 1px solid #ccc; 
    font-size: 14px;
    color: #666;
    text-align: center;
    box-sizing: border-box;
    margin-top: 20px;
    position: relative;
    bottom: 0;
  }
  
  /* Responsive design for small screens */
  @media (max-width: 768px) {
    .footer {
      height: 80px; /* Smaller height */
      font-size: 13px; /* Slightly smaller font */
      padding: 5px;
    }
  }
  
  /* Responsive design for extra small screens (mobile) */
  @media (max-width: 480px) {
    .footer {
      height: 70px; /* Smaller height */
      font-size: 12px; /* Smaller font for mobiles */
      padding: 5px;
    }
  
    .footer p {
      margin: 0 10px; /* Add margin for text to fit on smaller screens */
    }
  }
  


